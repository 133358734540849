import { Box, Button, Container, Grid, Stack, styled } from "@mui/material";
import { FlexBox } from "~/components/flex-box";
import { H2, H3 } from "~/components/Typography";
import FashionTransparentButton from "~/components/FashionSectionButton";
import BazaarImage from "~/components/BazaarImage";
import { FC } from "react";
import { Link } from "@remix-run/react";

// custom styled components
const ContentBox = styled(Box)(() => ({
  top: 20,
  left: 0,
  right: 0,
  textAlign: "center",
  position: "absolute",
}));

const Category2Wrapper = styled(Box)(() => ({
  width: "100%",
  height: "50%",
  position: "relative",
  display: "flex",
}));

const Category2ButtonWrapper = styled(Box)(() => ({
  left: 0,
  right: 0,
  bottom: 30,
  textAlign: "center",
  position: "absolute",
}));

type Props = { category; banner1; banner2; banner3 };

const FashionSection: FC<Props> = ({
  category,
  banner1,
  banner2,
  banner3,
}: Props) => {
  const men = category.find((item) => item.name === "Эрэгтэй хувцас");
  const women = category.find((item) => item.name === "Эмэгтэй хувцас");
  const children = category.find((item) => item.name === "Хүүхдийн хувцас");
  const accessories = category.find((item) => item.name === "Аксессуар");
  return (
    <Container sx={{ mb: { xs: 2, md: 4 } }}>
      <Grid container columnSpacing={3}>
        <Grid item sm={6} xs={12} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
            <BazaarImage
              alt="category"
              src={banner1[0].photoUrl}
              className="nomin-rounded-image"
              width={"100%"}
              height="100%"
            />

            <ContentBox>
              <H2
                fontSize={24}
                fontWeight="bold"
                fontFamily={"Rubik"}
                textTransform={"uppercase"}
              >
                Гутал хувцас
              </H2>
              {/* <H3 fontSize={22} fontWeight={400}>
                Коллекц
              </H3> */}
            </ContentBox>

            <FlexBox
              gap={3}
              justifyContent="center"
              sx={{ position: "absolute", bottom: 30, left: 0, right: 0 }}
            >
              <FashionTransparentButton size="large">
                <Link to={men.url}>Эрэгтэй</Link>
              </FashionTransparentButton>

              <FashionTransparentButton size="large">
                <Link to={women.url}>Эмэгтэй</Link>
              </FashionTransparentButton>
            </FlexBox>
          </Box>
        </Grid>

        <Grid
          item
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Stack spacing={2.5} style={{ fontWeight: "400" }}>
            <SingleCategory
              url={children.url}
              buttonText="Хүүхдийн хувцас"
              img={banner2[0].photoUrl}
              align={"left"}
            />

            <SingleCategory
              url={accessories.url}
              buttonText="Аксессуар"
              img={banner3[0].photoUrl}
              align={"right"}
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

// ============================================================================
type SingleCategoryProps = {
  img: string;
  url: string;
  buttonText: string;
  align: string;
};
// ===============================================================================

const SingleCategory: FC<SingleCategoryProps> = (props) => {
  const { img, url, buttonText, align } = props;

  return (
    <Category2Wrapper>
      <BazaarImage
        alt="category"
        src={img}
        className="nomin-rounded-image"
        width={"100%"}
      />

      <Box
        sx={{
          left: 0,
          right: 0,
          bottom: 30,
          textAlign: align,
          position: "absolute",
          px: 4,
        }}
      >
        <FashionTransparentButton size="large">
          <Link to={url}>{buttonText}</Link>
        </FashionTransparentButton>
      </Box>
    </Category2Wrapper>
  );
};

export default FashionSection;
