import { Button, ButtonProps } from "@mui/material";
import { FC } from "react";

// ==================================================
type FashionTransparentButton = { children: any };
// ==================================================

const FashionTransparentButton: FC<FashionTransparentButton & ButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button
      color="dark"
      variant="contained"
      sx={{
        color: "#fff",
        backgroundColor: "rgba(0,0,0,0.4)",
        borderRadius: "12px",
        fontSize: { md: "16px", xs: "12px" },
        fontWeight: 700,
        textTransform: "uppercase",
        ":hover": { backgroundColor: "rgba(0,0,0,0.6)", color: "#fff" },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default FashionTransparentButton;
